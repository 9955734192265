import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';
import Image from 'next/image';

import {Container} from '@/components/container';
import {ImageBlock} from '@/components/image/ImageBlock';
import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

type Props = PropsWithChildren & {
	heading?: string;
	imageWeb?: ImageWeb;
};

export const heroWithSideImagePortableTextOverrides: PortableTextOverrides = {};

export const HeroWithSideImage: FC<Props> = ({heading, imageWeb, children}) => {
	return (
		<div className={clsx('bg-primary', 'isolate', 'relative')}>
			<Container className={clsx('text-primary-light', 'z-10')}>
				<div className={clsx('grid', 'md:grid-cols-2', 'gap-10')}>
					<div className={clsx('max-w-[500px]', 'pt-16', 'md:pt-36', 'md:pb-36')}>
						<Heading spacing className={clsx('text-primary-light', 'mb-6')}>
							{heading}
						</Heading>
						<div>{children}</div>
					</div>
					<div className={clsx('hidden', 'md:block', 'relative', 'max-md:min-h-[400px]')}>
						{imageWeb && (
							<ImageFill
								priority // Images in page heads are usually part of the LCP
								src={imageWeb.image.src}
								width={imageWeb.image.width}
								height={imageWeb.image.height}
								hotspot={imageWeb.image.hotspot}
								alt={imageWeb.altText ?? ''}
							/>
						)}
					</div>
					<div className={clsx('md:hidden', 'pb-7')}>
						{imageWeb && (
							<ImageBlock
								priority // Images in page heads are usually part of the LCP
								src={imageWeb.image.src}
								width={imageWeb.image.width}
								height={imageWeb.image.height}
								alt={imageWeb.altText ?? ''}
							/>
						)}
					</div>
				</div>
			</Container>
			<div
				className={clsx(
					'absolute',
					'bottom-0',
					'left-0',
					'right-0',
					'h-[400px]',
					'opacity-10',
				)}
			>
				<Image
					src="/Vector.svg"
					fill
					style={{objectFit: 'cover', objectPosition: 'center'}}
					alt=""
				/>
			</div>
		</div>
	);
};
