import {RiText} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';

import {
	CALL_TO_ACTION,
	EMPHASIS,
	LINK,
	portableText,
	STRIKE_THROUGH,
	STRONG,
} from '../../portable-text/schema';

export const textHeading = () =>
	defineField({
		name: 'textHeading',
		title: 'Tekstoverskrift',
		icon: RiText,
		type: 'object',
		fields: [badge(), heading(), introduction(), align(), backgroundColor()],
		preview: {
			select: {
				title: 'heading',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Tekstoverskrift',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
		],
	});

export const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		description: 'Overskriften skal kort introdusere innholdet på siden.',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

export const introduction = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			decorators: [STRONG, EMPHASIS, STRIKE_THROUGH],
			customTypes: [CALL_TO_ACTION],
			styles: [],
			lists: [],
		}),
		name: 'introduction',
		title: 'Ingress',
		description: 'Ingressen skal kort introdusere innholdet på siden.',
		group: 'content',
	});

const badge = () =>
	defineField({
		name: 'badge',
		title: 'Badge',
		description: 'Viser en badge med en kort tekst.',
		type: 'string',
		group: 'content',
	});

const align = () =>
	defineField({
		name: 'align',
		title: 'Tekstjustering',
		description: 'Velg hvordan sidehodet skal justeres.',
		type: 'string',
		options: {
			list: [
				{
					title: 'Venstre',
					value: 'left',
				},
				{
					title: 'Senter',
					value: 'center',
				},
			],
			layout: 'radio',
		},
		initialValue: 'left',
		group: 'style',
	});

export type TextHeadingSchema = Output<typeof textHeading> & {_type: 'textHeading'};
