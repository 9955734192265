import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import clsx from 'clsx';
import Image from 'next/image';

import {Container} from '@/components/container';
import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
// import {WaveMask} from '@/components/masks/wave-mask';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

type Props = PropsWithChildren & {
	imageWeb?: ImageWeb;
	heading?: string;
};

export const heroWithBgImagePortableTextOverrides: PortableTextOverrides = {
	callToActionLine: 'justify-center',
};

export const HeroWithBgImage: FC<Props> = ({children, imageWeb, heading}) => {
	return (
		<div className={clsx('relative', 'isolate', 'text-white', 'bg-[#32226A]')}>
			<Container>
				<div
					className={clsx(
						'mx-auto',
						'max-w-3xl',
						'text-center',
						'min-h-[390px]',
						'md:min-h-[645px]',
						'flex',
						'items-center',
						'justify-center',
						'z-10',
					)}
				>
					{heading && (
						<Heading spacing className="text-white">
							{heading}
						</Heading>
					)}

					{children}
				</div>
			</Container>
			{imageWeb && (
				<ImageFill
					priority // Images in page heads are usually part of the LCP
					src={imageWeb.image.src}
					width={imageWeb.image.width}
					height={imageWeb.image.height}
					hotspot={imageWeb.image.hotspot}
					alt={imageWeb.altText ?? ''}
					className={clsx('mix-blend-soft-light', 'saturate-0', 'opacity-50')}
				/>
			)}
			<div className={clsx('absolute', 'inset-0')}>
				{/* <div className={clsx('bg-green-400', 'absolute', 'inset-0')}></div> */}
				<Image
					src="/Vector.svg"
					fill
					style={{objectFit: 'cover'}}
					alt=""
					className="opacity-20"
				/>
				{/* <WaveMask className={clsx('absolute', 'inset-x-0', 'bottom-0')} /> */}
			</div>
		</div>
	);
};
