import type {FC} from 'react';

import {TextHeading, textHeadingPortableTextOverrides} from '@/components/page-head/text-heading';

import {PortableText} from '../../portable-text';

import type {TextHeadingSchema} from './schema';

interface Props {
	data: TextHeadingSchema;
}

export const TextHeadingContainer: FC<Props> = ({data}) => {
	const {heading, introduction} = data;

	return (
		<TextHeading
			heading={heading}
			backgroundColor={data.backgroundColor}
			badge={data.badge}
			align={data.align}
		>
			{introduction && (
				<PortableText
					blocks={introduction}
					overrideClassNames={textHeadingPortableTextOverrides}
				/>
			)}
		</TextHeading>
	);
};
